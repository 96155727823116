<template>
  <div class="box" @scroll="scrollSetData">
    <!-- 头部 -->
    <headers></headers>
    <!-- 轮播图 -->
    <el-carousel :interval="3000">
      <el-carousel-item v-for="item in bannerImgList" :key="item.id">
        <img :src="item.url" class="imagess">
      </el-carousel-item>
    </el-carousel>
    <!-- 导航 -->
    <div class="content">
      <div class="content_01">
        <span v-for="item in dataList" @click="tebBtn(item.id)" :class="['p1', { 'p2': item.id == id }]"
          :key="item.id">{{
              item.name
          }}</span>
      </div>
    </div>
    <div class="contentData">
      <div style="margin: 20px;" v-for="item in navData" :key="item.id">
        <div class="elCard">
          <div class="camera">
            <div class="camera_img">
              <img :src="item.cameraUrl" alt="">
            </div>
            <div class="camera_text">
              <h2>{{ item.cameraName }}</h2>
              <p>{{ item.cameraTitle }}</p>
            </div>
          </div>
          <div class="imgList">
            <div class="imgList_top">
              <el-card class="card">
                <h2>{{ item.mobile_top }}</h2>
                <li v-if="item.liData != '' && item.list2Data == null">{{ item.liData }}</li>
                <p style="display: flex;" v-if="item.list2Data != null">
                  <li>{{ item.list2Data.list_1 }}</li>
                  <li style="margin-left: 8rem;">{{ item.list2Data.list_2 }}</li>
                </p>
              </el-card>
              <div class="card_bottom" :class="{ card_bottom_1: item.liData != '' || item.list2Data != null }">
                <!-- 技术优势 -->
                <div class="box" v-for="e in item.mobileList" :key="e.id">
                  <p v-if="item.liData == '' && item.list2Data == null">{{ e.name }}</p>
                  <img :class="{ igs: item.isHeight }" :src="e.url" alt="">
                </div>
              </div>
            </div>
            <div class="imgList_bottom" :class="{ imgList_bottom_1: item.mobile_title == '笔记本电脑' }">
              <el-card class="card">
                <h2>{{ item.mobile_bottom }}</h2>
                <p>{{ item.mobile_title }}</p>
              </el-card>
              <div class="box_1">
                <div class="box_01" v-for="obj in item.imgLists" :key="obj.id" v-if="item.imgLists == null">
                  <img class="img_2" :src="obj.url" alt="" v-if="item.imgs == ''">
                </div>
                <div class="box_02" v-for="obj in item.imgLists" :key="obj.id" v-if="item.imgLists != null">
                  <img :src="obj.url" alt="" v-if="item.imgs == ''">
                </div>
                <img :src="item.imgs" img_11alt=""
                  :class="[{ img_11: item.imgs != '' }, { img_22: item.mobile_title == '笔记本电脑' || item.Veh }]" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <p element-loading-text="加载中，请稍后..." v-loading="loading" style="height: 5.5rem;"></p>
      <p v-if="loading" class="loading"></p>
      <p class="data_p" v-if="noMore" style="color: rgb(64,158,255);">数据加载完毕</p>
      <!-- <p class="data_p" v-if="id!=5">没有数据</p> -->
      <!-- <div v-else style="width: 100%;height:300px">111</div> -->
    </div>
    <!-- 底部 -->
    <foot @submitData="submitData"></foot>

  </div>
  <div class="_div">
    <!--  小屏-->
    <!-- 头部 -->
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="item in bannerImgList" :key="item.id">
        <img :src="item.url" style="width: 100%;">
      </van-swipe-item>
    </van-swipe>
    <!-- 中间内容区域 -->
    <div class="content_top">
      <p v-for="item in dataList_move" @click="tebBtn(item.id)" :key="item.id" :class="{ p1: item.id == id }">{{
          item.name
      }}
      </p>

    </div>

    <div class="content_move" v-for="item in navData" :key="item.id">
      <div class="box_top">
        {{ item.name }}
        <div class="box_top_1"></div>
      </div>
      <div class="box_bottom">
        <div class="img_left">
          <img :src="item.cameraUrl_move" alt="">
          <h2>产品解析</h2>
          <p>{{ item.cameraTitle }}</p>
        </div>
        <div class="img_right">
          <h2>技术优势</h2>
          <p>{{ item.liData }}</p>
          <img v-for="e in item.mobileList_move" :key="e.id" :src="e.url" alt="" v-if="item.mobileList_move != null"
            style="width: 35%;">
          <img :src="item.mobileList[0].url" alt="" v-if="item.mobileList_move == null" style="width: 50rem;">
          <h2 style="margin-top: 7rem;">应用场景</h2>
          <p>{{ item.mobile_title }}</p>
          <img :src="item.imgLists[0].url" alt="" v-if="!item.isIMG" style="width: auto;height: 22rem;"
            :class="{ che: item.Veh == true }">
          <!-- <div class="IMG_box" v-if="item.isIMG">
            <img class="isIMGData" :src="i.url" alt=""  v-for="i in item.imgLists" :key="i.id"/>
          </div>   -->
          <img style="width: auto;height:17rem;margin-top: 3rem;" :src="i.url" alt="" v-for="i in item.imgLists"
            :key="i.id" v-if="item.isIMG" />
        </div>
      </div>
    </div>
    <p element-loading-text="加载中，请稍后..." v-loading="loading" style="height: 13rem;"></p>
    <p v-if="loading" class="loading"></p>
    <p class="data_p" v-if="noMore" style="color: rgb(64,158,255);margin: 5 rem 0;">数据加载完毕</p>
    <!-- 底部 -->
    <move_foot @submitData="submitData"></move_foot>
  </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import { reactive, toRefs, onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import move_header from "../move_components/move_header.vue";
import move_foot from "../move_components/move_foot.vue";
export default {
  components: { foot, headers, move_foot, move_header },
  setup() {
    const route = useRouter();
    const noMore = ref(false)
    const isRefreshBool = ref(true)
    const el_card = ref()
    const state = reactive({
      loading: false,
      id: localStorage.getItem('id') || 5,
      bannerImgList: [
        { id: 1, url: require('@/assets/homePage/product1.png') },
        { id: 2, url: require('@/assets/homePage/product2.png') },
        { id: 3, url: require('@/assets/homePage/product3.png') },
        { id: 4, url: require('@/assets/homePage/product4.png') },
        { id: 5, url: require('@/assets/homePage/product5.png') },
      ],
      dataList_move: [
        { id: 5, name: '移动终端', url: '', content: '' },
        { id: 4, name: 'NB产品', url: '', content: '' },
        { id: 3, name: '智能物联网', url: '', content: '' },
        { id: 2, name: '车载及医疗', url: '', content: '' },
        { id: 1, name: 'LED', url: '', content: '' },
      ],
      dataList: [
        { id: 1, name: 'LED', url: '', content: '' },
        { id: 2, name: '车载及医疗', url: '', content: '' },
        { id: 3, name: '智能物联网', url: '', content: '' },
        { id: 4, name: 'NB产品', url: '', content: '' },
        { id: 5, name: '移动终端', url: '', content: '' },
      ],
      navData: [],

      // LED数据
      LEDList: [{
        id: 1, isIMG: true, name: 'LED模组', liData: '可实现精细的Local Dimming,实现超高对比度，颜色对比更明显', cameraUrl_move: require('@/assets/product_center/LED/move_1.png'), isHeight: false, imgs: '', cameraUrl: require('@/assets/product_center/LED/LED.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '手机，笔记本电脑，AR/VR眼镜等。', mobile_bottom: '应用场景', cameraTitle: 'LED模组是通过SMD或COB等制程，将LED贴件于PCB、玻璃基板上，实现背光等用途。产品应用于电视、笔电，平板电脑、汽车、手机、VR/AR眼镜等场景。',
        list2Data: { list_1: '可实现精细的Local Dimming', list_2: '实现超高对比度，颜色对比更明显' },
        mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/LED/1.png') },
        { id: 2, name: '前摄大视野', url: require('@/assets/product_center/LED/2.png') },
        { id: 3, name: '后摄辅助光学变焦', url: require('@/assets/product_center/LED/3.png') },
        { id: 4, name: '后摄辅助增加光量', url: require('@/assets/product_center/LED/4.png') }],
        mobileList_move:
          [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/LED/1.png') },
          { id: 2, name: '前摄大视野', url: require('@/assets/product_center/LED/2.png') },],
        imgLists: [
          { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
          { id: 2, url: require('@/assets/product_center/LED/电脑.png') },
          { id: 3, url: require('@/assets/product_center/LED/眼镜.png') },
        ]

      },
      {
        id: 2, isIMG: true, name: 'logo显示LED模组', liData: 'FPC+LGF模组+LED+connector,LED3.0*1.0*0.4mm,RGB,20mA恒流驱动', cameraUrl_move: require('@/assets/product_center/LED/move_2.png'), isHeight: false, imgs: '', cameraUrl: require('@/assets/product_center/LED/logo显示模组.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '平板电脑，笔记本电脑，手机等', mobile_bottom: '应用场景', cameraTitle: 'COG01T为GAMING PHONE上的应用，通过LGF（导光膜）将两颗LED的光导成面光源，实现发光的目的。',
        list2Data: { list_1: 'FPC+LGF模组+LED+connector', list_2: 'LED3.0*1.0*0.4mm,RGB,20mA恒流驱动' },
        mobileList: [{ id: 1, name: '背景虚化', url: require('@/assets/product_center/LED/5.png') },
        { id: 2, name: '超大光圈', url: require('@/assets/product_center/LED/6.png') },
        { id: 3, name: '夜间拍摄效果清晰', url: require('@/assets/product_center/LED/7.png') },
        { id: 4, name: '超高动态范围捕捉', url: require('@/assets/product_center/LED/8.png') }],
        mobileList_move: [
          { id: 1, name: '背景虚化', url: require('@/assets/product_center/LED/5.png') },
          { id: 2, name: '超大光圈', url: require('@/assets/product_center/LED/6.png') },
        ],
        imgLists: [
          { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
          { id: 2, url: require('@/assets/product_center/LED/电脑.png') },
          { id: 2, url: require('@/assets/product_center/LED/平板.png') },
        ]
      }],
      // NB数据
      NBList: [
        {
          id: 1, name: '超窄/超薄 NB Camera', cameraUrl_move: require('@/assets/product_center/NB/move_1.png'), imgs: require('@/assets/product_center/NB/电脑的副本2.png'), liData: '分辨率FHD（2M，1080P）RGB传感器OV02CRGB镜头Powertip 4P AA镜头', cameraUrl: require('@/assets/product_center/NB/NB产品1.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '笔记本电脑', mobile_bottom: '应用场景', cameraTitle: '该产品应用于轻薄笔记本型电脑，模组采用COB工艺制造，提供200万有效像素输出，产品高度仅2.58毫米，宽度仅3毫米。',
          mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/NB/1.png') },
          { id: 2, name: '前摄大视野', url: require('@/assets/product_center/NB/2.png') }],
          mobileList_move: null,
          imgLists: [
            { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
            { id: 2, url: require('@/assets/product_center/mobile/图2.png') },
            { id: 3, url: require('@/assets/product_center/mobile/图3.png') },
          ],
        },
        {
          id: 2, name: 'NB双摄 Camera', cameraUrl_move: require('@/assets/product_center/NB/move_2.png'), imgs: require('@/assets/product_center/NB/电脑的副本2.png'), liData: '支持AI  生物识别/防偷窥', cameraUrl: require('@/assets/product_center/NB/NB产品2.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '笔记本电脑', mobile_bottom: '应用场景', cameraTitle: '分辨率2M（FHD）+1M（HD）、RGB传感器OV02C\U COB、RGB透镜PT 42091、红外传感器HM1092\U CSP、红外镜头Powertip 3P镜头。',
          mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/NB/3.png') },
          { id: 2, name: '前摄大视野', url: require('@/assets/product_center/NB/4.png') }],
          mobileList_move: null,
          imgLists: [
            { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
            { id: 2, url: require('@/assets/product_center/mobile/图2.png') },
            { id: 3, url: require('@/assets/product_center/mobile/图3.png') },
          ],
        },
      ],
      // 车载
      vehicleList: [
        {
          id: 1, name: "枕屏幕摄像模组-4k(32m)", cameraUrl_move: require('@/assets/product_center/vehicle/move_1.png'), isHeight: false, Veh: true, imgs: '', liData: '360度环视(AVM)前瞻技术 32M 后座枕方', cameraUrl: require('@/assets/product_center/vehicle/车载1.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '比亚迪_汉系列&元系列', mobile_bottom: '应用场景', cameraTitle: '该产品应用于比亚迪 汉系列 & 元系列,汉系列为前瞻技术 32M 后座枕方案，元系列为环景视讯方案',
          list2Data: null,
          isIMG: false,
          mobileList: [
            { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/vehicle/1.png') },
            { id: 2, name: '前摄大视野', url: require('@/assets/product_center/vehicle/2.png') }],
          mobileList_move: null,
          imgLists: [
            { id: 1, url: require('@/assets/product_center/vehicle/车1.png') },
            { id: 2, url: require('@/assets/product_center/vehicle/车2.png') },
          ],

        },
        {
          id: 2, name: 'DMS/DVR/360环景/OMS', cameraUrl_move: require('@/assets/product_center/vehicle/move_2.png'), isHeight: false, Veh: true, imgs: '', liData: '记录更清晰/记录范围更大/存储更便捷/智能自动侦测/画面更自然', cameraUrl: require('@/assets/product_center/vehicle/车载2.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '一汽、长安、东风_中汽创智', mobile_bottom: '应用场景', cameraTitle: '本产品用于一汽、长安、东风-中汽创智等车型，具有记录更清晰，记录范围大存储更便捷等多种优势。',
          list2Data: null,
          isIMG: false,
          mobileList: [
            { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/vehicle/3.png') },
            { id: 2, name: '前摄大视野', url: require('@/assets/product_center/vehicle/4.png') }],
          mobileList_move: null,
          imgLists: [
            { id: 1, url: require('@/assets/product_center/vehicle/车3.png') },
            { id: 2, url: require('@/assets/product_center/vehicle/车4.png') },
          ],
        }
      ],
      // 智能物联网
      AIoTList: [
        {
          id: 1, name: 'IPC摄像头', cameraUrl_move: require('@/assets/product_center/AIoT/move_1.png'), isHeight: false, imgs: '', liData: '支持USB3.0传输协议、4K影像输出，以及Windows Hello人脸识别登录、Teams、Lync等视讯会议等认证', cameraUrl: require('@/assets/product_center/AIoT/摄像头.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '笔记本电脑，台式电脑', mobile_bottom: '应用场景', cameraTitle: 'BW831C是一款8百万像素外置摄像头产品，具有拍照和视频功能，专为有高像素视讯会议、高清网络直播等需求的受众群体设计',
          list2Data: null,
          mobileList: [
            { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/AIoT/1.png') },
            { id: 2, name: '前摄大视野', url: require('@/assets/product_center/AIoT/2.png') }],
          mobileList_move: null,
          imgLists: [
            { id: 1, url: require('@/assets/product_center/AIoT/电脑的副本.png') },
            { id: 2, url: require('@/assets/product_center/AIoT/电脑.png') },
          ],

        },
        {
          id: 2, name: '外接4K视频会议摄像头', cameraUrl_move: require('@/assets/product_center/AIoT/move_2.png'), isHeight: false, imgs: '', liData: '3P镜头，GC2063传感器GK7205低功耗高性能SOC', cameraUrl: require('@/assets/product_center/AIoT/IPC.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '3P镜头，GC2063传感器GK7205低功耗高性能SOC', mobile_bottom: '应用场景', cameraTitle: '3P镜头，GC2063传感器GK7205低功耗高性能SOC支持1080P HDR视频模式',
          list2Data: null,
          mobileList_move: null,
          mobileList: [
            { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/AIoT/3.png') },
            { id: 2, name: '前摄大视野', url: require('@/assets/product_center/AIoT/4.png') }],
          imgLists: [
            { id: 1, url: require('@/assets/product_center/AIoT/5.png') },
            { id: 2, url: require('@/assets/product_center/AIoT/6.png') },
          ],
        }
      ]
    })
    let methods = {
      tebBtn(id) {
        state.id = id
        localStorage.setItem('id', id)
        noMore.value = false
        if (state.id == 1) {
          state.navData = state.LEDList
        } else if (state.id == 2) {
          state.navData = state.vehicleList
        } else if (state.id == 3) {
          state.navData = state.AIoTList
        } else if (state.id == 4) {
          state.navData = state.NBList
        } else if (state.id == 5) {
          state.navData = [
            {
              id: 1, isHeight: false, name: '超大广角模组', imgs: '', liData: '前摄高清美艳，前摄大视野，后摄辅助光学变焦，后摄辅助增加光量', cameraUrl_move: require('@/assets/product_center/mobile/超大广角/move_1.png'), cameraUrl: require('@/assets/product_center/mobile/超大广角/产品.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '聚焦科技创新，争做光学百年企业,打造优质产品', mobile_bottom: '应用场景', cameraTitle: '1600万像素大广角,是通过增大镜头的视场角(FOV)来增加拍照视野提升进光量、多倍变焦等功能。',
              list2Data: null,
              mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/超大广角/超大广角1.png') },
              { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/超大广角/超大广角2.png') },
              { id: 3, name: '后摄辅助光学变焦', url: require('@/assets/product_center/mobile/超大广角/超大广角3.png') },
              { id: 4, name: '后摄辅助增加光量', url: require('@/assets/product_center/mobile/超大广角/超大广角4.png') }],
              mobileList_move: [
                { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/超大广角/超大广角1.png') },
                { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/超大广角/超大广角2.png') },
              ],
              imgLists: [
                { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
              ]
            },
            {
              id: 2, isHeight: false, name: '双摄模组', imgs: '', liData: '背景虚化，超大光圈，夜间拍摄效果清晰，超高动态范围捕捉', cameraUrl_move: require('@/assets/product_center/mobile/双摄模组/move_1.png'), cameraUrl: require('@/assets/product_center/mobile/双摄模组/产品2.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '聚焦科技创新，争做光学百年企业,打造优质产品', mobile_bottom: '应用场景', cameraTitle: '4800万像素可对焦摄像头,同500万像素固定焦距摄像头,通过金属支架组合在一起,形成一个组合摄像头。可以增加进光量、背景虚化、高动态范围拍照。',
              list2Data: null,
              mobileList: [{ id: 1, name: '背景虚化', url: require('@/assets/product_center/mobile/双摄模组/双摄模组1.png') },
              { id: 2, name: '超大光圈', url: require('@/assets/product_center/mobile/双摄模组/双摄模组2.png') },
              { id: 3, name: '夜间拍摄效果清晰', url: require('@/assets/product_center/mobile/双摄模组/双摄模组3.png') },
              { id: 4, name: '超高动态范围捕捉', url: require('@/assets/product_center/mobile/双摄模组/双摄模组4.png') }],
              mobileList_move: [
                { id: 1, name: '背景虚化', url: require('@/assets/product_center/mobile/双摄模组/双摄模组1.png') },
                { id: 2, name: '超大光圈', url: require('@/assets/product_center/mobile/双摄模组/双摄模组2.png') },
              ],
              imgLists: [
                { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
              ]
            }
          ]
        }
        setTimeout(() => {
          methods.alternate()
        })

      },
      submitData(id) {
        methods.tebBtn(id)
        // console.log('id',id);
      },
      scrollSetData() {
        window.onscroll = () => {
          //变量scrollTop是滚动条滚动时，距离顶部的距离
          let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
          //变量windowHeight是可视区的高度
          let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
          //变量scrollHeight是滚动条的总高度
          let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
          //滚动条到底部的条件(距底部20px时触发加载)
          let Date = 1000
          if (
            scrollTop + windowHeight >= scrollHeight - 400
          ) {
            let id = localStorage.getItem('id') || 5
            let num = state.navData.length
            if (id == 5 && num <= 3 && isRefreshBool.value) {
              isRefreshBool.value = false
              state.loading = true
              setTimeout(() => {
                state.navData.push(
                  {
                    id: 3, name: 'OIS光学防抖模组', cameraUrl_move: require('@/assets/product_center/mobile/OIS光学防抖模组/move_1.png'), isHeight: false, imgs: '', liData: 'OIS光学防抖能通过算法自动对拍摄画面进行补偿，可以有效克服相机震动导致的画面模糊，带来更加清晰，流畅的拍摄体验', cameraUrl: require('@/assets/product_center/mobile/OIS光学防抖模组/1.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '手持拍摄，车内摄影，移动视频摄影', mobile_bottom: '应用场景', cameraTitle: 'OIS光学防抖摄像头可以避免因手抖和外界抖动照成的画面模糊问题，保证画面清晰度',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/OIS光学防抖模组/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/OIS光学防抖模组/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ]
                  },
                  {
                    id: 12, name: 'G+P大底OIS摄像头', cameraUrl_move: require('@/assets/product_center/mobile/G+P大底OIS摄像头/move_1.png'), isHeight: false, imgs: '', liData: '“G+P”镜头技术，通过非球面模造玻璃（1G+5P）提升了镜头的光学性能，非球面模造玻璃的应用有助于滤除杂光、降低色散，结合大底芯片实现更加细腻，拟真的摄影效果，再结合OIS防抖技术，达到极佳的拍照摄影体验。', cameraUrl: require('@/assets/product_center/mobile/G+P大底OIS摄像头/1.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '手机远近景摄影，夜景拍摄，移动视频摄影', mobile_bottom: '应用场景', cameraTitle: 'G+P大底OIS摄像头通过G+P镜头，大底芯片与OIS马达的结合，实现即使在暗弱光或夜景时也能产出高品质图片',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/G+P大底OIS摄像头/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/G+P大底OIS摄像头/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  },

                )
                isRefreshBool.value = true
                state.loading = false
              }, Date)
            } else if (id == 5 && num <= 5 && isRefreshBool.value) {
              isRefreshBool.value = false
              state.loading = true
              setTimeout(() => {
                state.navData.push(
                  {
                    id: 5, name: '显微镜模组', cameraUrl_move: require('@/assets/product_center/mobile/显微镜模组/move_1.png'), isHeight: false, imgs: require('@/assets/product_center/mobile/显微镜模组/4.png'), liData: '显微镜摄像头模组可以拍摄3mm超近距离物体，搭配手机算法可以实现60X显微镜效果，可以通过手机拍摄探索微观世界', cameraUrl: require('@/assets/product_center/mobile/显微镜模组/显微镜模组.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '超微距拍摄，显微镜观察', mobile_bottom: '应用场景', cameraTitle: '显微镜摄像头模组可以应用手机算法，实现60X放大功能，实现显微镜效果',
                    list2Data: null,
                    mobileList_move: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/显微镜模组/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/显微镜模组/3.png') }],
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/显微镜模组/4.png') }
                    ],

                  },
                  {
                    id: 6, name: '一体化前置摄像头', cameraUrl_move: require('@/assets/product_center/mobile/一体化前置摄像头/move_1.png'), isHeight: false, imgs: '', liData: '一体式前摄摄像头头部直径小，可减少手机外露摄像头尺寸，提高手机屏占比，提升手机整体视觉感受', cameraUrl: require('@/assets/product_center/mobile/一体化前置摄像头/1.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '手机自拍', mobile_bottom: '应用场景', cameraTitle: '一体化前置摄像头头部直径小，镜头解析力高，被广泛应用于手机前置摄像头',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/一体化前置摄像头/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/一体化前置摄像头/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  }
                )
                isRefreshBool.value = true
                state.loading = false
              }, Date)
            } else if (id == 5 && num <= 7 && isRefreshBool.value) {
              isRefreshBool.value = false
              state.loading = true
              setTimeout(() => {
                state.navData.push(
                  {
                    id: 7, name: '高像素AF摄像头', cameraUrl_move: require('@/assets/product_center/mobile/高像素AF摄像头/move_1.png'), isHeight: false, imgs: '', liData: '高像素AF模组可以实现自动快速对焦功能，拍摄不同距离的物品时自动对焦，带来最清晰的拍摄影像', cameraUrl: require('@/assets/product_center/mobile/高像素AF摄像头/1.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '手机远近景摄影', mobile_bottom: '应用场景', cameraTitle: '高像素AF模组可以实现自动对焦功能，拍摄不同距离的高像素影像，作为主摄使用',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/高像素AF摄像头/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/高像素AF摄像头/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  },
                  {
                    id: 8, name: '200M超高像素AF摄像头', cameraUrl_move: require('@/assets/product_center/mobile/200M超高像素AF摄像头/move_1.png'), isHeight: false, imgs: '', liData: '200M超高像素AF摄像头在满足自动对焦的同时，可以满足极限2亿像素的超高清影像拍摄，每一处细节放大后依然清晰可见', cameraUrl: require('@/assets/product_center/mobile/200M超高像素AF摄像头/1.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '手机远近景摄影', mobile_bottom: '应用场景', cameraTitle: '200M超高像素AF摄像头可以拍摄行业像素最高的2亿像素超高清照片',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/200M超高像素AF摄像头/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/200M超高像素AF摄像头/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  },
                )
                isRefreshBool.value = true
                state.loading = false
              }, Date)
            } else if (id == 5 && num <= 9 && isRefreshBool.value) {
              isRefreshBool.value = false
              state.loading = true
              setTimeout(() => {
                state.navData.push(
                  {
                    id: 9, name: '潜望式长焦摄像头', cameraUrl_move: require('@/assets/product_center/mobile/潜望式长焦摄像头/move_1.png'), isHeight: false, imgs: '', liData: '潜望镜式长焦摄像头在成像上中央和边缘的锐度差别不大,让画面的细腻度得到很好的平衡，潜望式的结构设计，将长焦镜头的高度转化为宽度，使得手机可以保持超薄的尺寸', cameraUrl: require('@/assets/product_center/mobile/潜望式长焦摄像头/1.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '超远距离手机摄影', mobile_bottom: '应用场景', cameraTitle: '潜望式长焦摄像头，可以实现5x的光学变焦，拍摄超远距离物体时保持高清晰度',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/潜望式长焦摄像头/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/潜望式长焦摄像头/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  },
                  {
                    id: 10, name: '伸缩式长焦摄像头', cameraUrl_move: require('@/assets/product_center/mobile/伸缩式长焦摄像头/move_1.png'), isHeight: false, imgs: '', liData: '伸缩式长焦摄像头工作时伸出镜头，非工作时收回，可有效减小摄像头尺寸，满足手机尺寸要求，且伸缩式结构可以搭配大尺寸的大底芯片，提升拍摄效果', cameraUrl: require('@/assets/product_center/mobile/伸缩式长焦摄像头/1.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '远距离手机摄影', mobile_bottom: '应用场景', cameraTitle: '伸缩式长焦摄像头通过伸缩弹出镜头实现变焦，拍摄远距离物体',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/伸缩式长焦摄像头/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/伸缩式长焦摄像头/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  }
                )
                isRefreshBool.value = true
                state.loading = false
              }, Date)
            } else if (id == 5 && num <= 11 && isRefreshBool.value) {

              isRefreshBool.value = false
              state.loading = true

              setTimeout(() => {
                state.navData.push(
                  {
                    id: 13, name: '广角+长焦+高像素三摄', cameraUrl_move: require('@/assets/product_center/mobile/广角+长焦+高像素三摄/move_1.png'), isHeight: true, imgs: '', liData: '广角+长焦+高像素三摄，通过将三个摄像头组合到一起，广角和长焦摄像头辅助高像素主摄拍照，使照片表现力更加清晰，同时使摄像头有更多的功能，减少画质的曝光率，拍出更好的照片', cameraUrl: require('@/assets/product_center/mobile/广角+长焦+高像素三摄/1.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '手机多场景拍摄', mobile_bottom: '应用场景', cameraTitle: '广角+长焦+高像素三摄，通过三种摄像头的结合，覆盖手机摄影各使用场景需求',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/广角+长焦+高像素三摄/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/广角+长焦+高像素三摄/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  },
                  {
                    id: 14, name: '景深计算双摄模块', cameraUrl_move: require('@/assets/product_center/mobile/景深计算双摄模块/move_1.png'), isHeight: false, imgs: '', liData: '景深计算双摄模块主要计算景深，实现背景虚化和重对焦：可以通过调节光圈大小，来改变照片的虚化程度，重新调用照片中物体的景深信息，可以实现先拍照后对焦的功能（重对焦功能）', cameraUrl: require('@/assets/product_center/mobile/景深计算双摄模块/1.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '手机远近景摄影，背景虚化', mobile_bottom: '应用场景', cameraTitle: '景深计算双摄模块通过景深摄像头与主摄的结合，实现重对焦功能',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/景深计算双摄模块/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/景深计算双摄模块/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  },
                )
                isRefreshBool.value = true
                state.loading = false
                noMore.value = true
              }, Date)
            }
             
            if (id == 2 && num <= 3 && isRefreshBool.value) {
              isRefreshBool.value = false
              state.loading = true
              setTimeout(() => {
                state.navData.push(
                    {
                      id: 3, name: "DMS/OMS/DVR/娱乐摄像模组", cameraUrl_move: require('@/assets/product_center/vehicle/move_3.png'), isHeight: false, Veh: true, imgs: '', liData: '1M/2M 360环视 2M ADAS 前视一体机 8M ADAS', cameraUrl: require('@/assets/product_center/vehicle/娱乐摄像模组1.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '比亚迪，广汽，沃尔沃等客户', mobile_bottom: '应用场景', cameraTitle: '本产品为智能座舱内自动驾驶辅助产品，1M/2M 360环视，量产客户为比亚迪，广汽，沃尔沃等客户。',
                      list2Data: null,
                      isIMG: false,
                      mobileList: [
                        { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/vehicle/5.png') },
                        { id: 2, name: '前摄大视野', url: require('@/assets/product_center/vehicle/6.png') }],
                      mobileList_move: null,
                      imgLists: [
                        { id: 1, url: require('@/assets/product_center/vehicle/车5.png') },
                        { id: 2, url: require('@/assets/product_center/vehicle/车6.png') },
                      ],

                    },
                    {
                      id: 4, name: 'DMS/OMS/DVR/娱乐摄像模组', cameraUrl_move: require('@/assets/product_center/vehicle/move_4.png'), isHeight: false, Veh: true, imgs: '', liData: '1M/2M 360环视 2M ADAS 前视一体机 8M ADAS', cameraUrl: require('@/assets/product_center/vehicle/娱乐摄像模组2.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '长城、小鹏、蔚来、丰田等客户。', mobile_bottom: '应用场景', cameraTitle: '本产品为智能座舱内支持娱乐功能的视频输入设备，可支持实时影像技术，具有游戏、电视等娱乐功能，可支持视频通话。深入合作已定点客户为长城、小鹏、蔚来、丰田等客户。',
                      list2Data: null,
                      isIMG: false,
                      mobileList: [
                        { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/vehicle/7.png') },
                        { id: 2, name: '前摄大视野', url: require('@/assets/product_center/vehicle/8.png') }],
                      mobileList_move: null,
                      imgLists: [
                        { id: 1, url: require('@/assets/product_center/vehicle/车7.png') },
                        { id: 2, url: require('@/assets/product_center/vehicle/车8.png') },
                      ],
                    },

                    {
                  id: 5, isIMG: true, name: 'MCI-全自动菌落计数器', liData: '可准确、快速实现高密度、复杂背景的菌落计数。自动保存所有数据、图片和结果，检测最小菌落为0.10毫米', 
                  cameraUrl_move: require('@/assets/product_center/vehicle/菌落计数仪.png'), 
                  isHeight: false, imgs: '', cameraUrl: require('@/assets/product_center/vehicle/菌落计数仪.png'), 
                  cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '食品饮料行业/生物医学/疾病控制/科研机构高校。', mobile_bottom: '应用场景', 
                  cameraTitle: '搭载盛泰自研高精度菌落检测识别AI大模型，可实现1秒内高密度菌落的快速计数，精度达0.05毫米，采用盛泰定制的高像素微距模组，实现样品的高清拍照留存。',
                  list2Data: { list_1: '可准确、快速实现高密度、复杂背景的菌落计数', list_2: '自动保存所有数据、图片和结果，检测最小菌落为0.10毫米'},
                  mobileList: [//技术优势图片
                        { id: 1, name: '技术优势1', url: require('@/assets/product_center/vehicle/菌落计数仪-技术优势1.png') },
                        { id: 2, name: '技术优势2', url: require('@/assets/product_center/vehicle/菌落计数仪-技术优势2.png') },
                        ],
                   
                      imgLists: [  //应用场景图片
                        { id: 1, url: require('@/assets/product_center/vehicle/菌落计数仪-应用场景1.png') },
                        { id: 2, url: require('@/assets/product_center/vehicle/菌落计数仪-应用场景2.png') },
                      ],
                      mobileList_move: null,
                  // mobileList_move:
                  //   [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/LED/1.png') },
                  //   { id: 2, name: '前摄大视野', url: require('@/assets/product_center/LED/2.png') },],          

      },
                    
                    {
                  id: 6, isIMG: true, name: 'MCI-50微生物培养检测工作站', liData: '搭载高通QCS6490平台，独立运行深度学习算法的智能终端,盛泰自研光学系统及具有核心专利深度学习算法', 
                  cameraUrl_move: require('@/assets/product_center/vehicle/工作站图片.png'), 
                  isHeight: false, imgs: '', cameraUrl: require('@/assets/product_center/vehicle/工作站图片.png'), 
                  cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '食品饮料行业/生物医学/疾病控制/科研机构高校。', mobile_bottom: '应用场景', 
                  cameraTitle: 'MCI-50设备是基于盛泰自研的光学检测系统，搭载高精度菌落检测识别AI大模型，实现在不同应用邻域复杂场景条件下的快速精确的菌落计数。',
                  list2Data: { list_1: '搭载高通QCS6490平台，独立运行深度学习算法的智能终端', list_2: '盛泰自研光学系统及具有核心专利深度学习算法'},
                  mobileList: [//技术优势图片
                        { id: 1, name: '技术优势1', url: require('@/assets/product_center/vehicle/工作站-技术优势1.png') },
                        { id: 2, name: '技术优势2', url: require('@/assets/product_center/vehicle/工作站-技术优势2.png') },
                        ],
                   
                      imgLists: [  //应用场景图片
                        { id: 1, url: require('@/assets/product_center/vehicle/工作站-应用场景1.png') },
                        { id: 2, url: require('@/assets/product_center/vehicle/工作站-应用场景2.png') },
                      ],
                      mobileList_move: null,
                  // mobileList_move:
                  //   [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/LED/1.png') },
                  //   { id: 2, name: '前摄大视野', url: require('@/assets/product_center/LED/2.png') },],          

      },

                )
                isRefreshBool.value = true
                state.loading = false
                noMore.value = true
              }, Date)
            }
          }
          methods.alternate()
        }
      },
      // 控制左右布局的代码
      alternate() {
        let elCard = document.getElementsByClassName('elCard')
        let box_top = document.getElementsByClassName('box_top')
        let box_top_1 = document.getElementsByClassName('box_top_1')
        let box_bottom = document.getElementsByClassName('box_bottom')
        setTimeout(() => {
          for (let i = 0; i < box_top.length; i++) {
            if (i % 2 == 0) {
              box_top[i].style.marginLeft = '0rem'
              box_top_1[i].style.left = ''
              box_bottom[i].style.flexDirection = 'row'
            } else {
              box_top[i].style.marginLeft = '58rem'
              box_top_1[i].style.left = '-58rem'
              box_bottom[i].style.flexDirection = 'row-reverse'
            }
          }
        })
        // console.log(elCard);
        for (let i = 0; i < elCard.length; i++) {
          if (i % 2 == 0) {
            elCard[i].style.flexDirection = 'row'
          } else {
            elCard[i].style.flexDirection = 'row-reverse'
          }
        }
      }
    }

    onMounted(() => {
      methods.scrollSetData()
      // methods.tebBtn(localStorage.getItem('id'))
      methods.alternate()
      if (state.id == 5) {
        state.navData = [{
          id: 1, isHeight: false, name: '超大广角模组', imgs: '', liData: '前摄高清美艳，前摄大视野，后摄辅助光学变焦，后摄辅助增加光量', cameraUrl_move: require('@/assets/product_center/mobile/超大广角/move_1.png'), cameraUrl: require('@/assets/product_center/mobile/超大广角/产品.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '聚焦科技创新，争做光学百年企业,打造优质产品', mobile_bottom: '应用场景', cameraTitle: '1600万像素大广角,是通过增大镜头的视场角(FOV)来增加拍照视野提升进光量、多倍变焦等功能。',
          list2Data: null,
          mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/超大广角/超大广角1.png') },
          { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/超大广角/超大广角2.png') },
          { id: 3, name: '后摄辅助光学变焦', url: require('@/assets/product_center/mobile/超大广角/超大广角3.png') },
          { id: 4, name: '后摄辅助增加光量', url: require('@/assets/product_center/mobile/超大广角/超大广角4.png') }],
          mobileList_move: [
            { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/超大广角/超大广角1.png') },
            { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/超大广角/超大广角2.png') },
          ],
          imgLists: [
            { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
            { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
            { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
          ]
        },
        {
          id: 2, isHeight: false, name: '双摄模组', imgs: '', liData: '背景虚化，超大光圈，夜间拍摄效果清晰，超高动态范围捕捉', cameraUrl_move: require('@/assets/product_center/mobile/双摄模组/move_1.png'), cameraUrl: require('@/assets/product_center/mobile/双摄模组/产品2.png'), cameraName: '产品解析', mobile_top: '技术优势', mobile_title: '聚焦科技创新，争做光学百年企业,打造优质产品', mobile_bottom: '应用场景', cameraTitle: '4800万像素可对焦摄像头,同500万像素固定焦距摄像头,通过金属支架组合在一起,形成一个组合摄像头。可以增加进光量、背景虚化、高动态范围拍照。',
          list2Data: null,
          mobileList: [{ id: 1, name: '背景虚化', url: require('@/assets/product_center/mobile/双摄模组/双摄模组1.png') },
          { id: 2, name: '超大光圈', url: require('@/assets/product_center/mobile/双摄模组/双摄模组2.png') },
          { id: 3, name: '夜间拍摄效果清晰', url: require('@/assets/product_center/mobile/双摄模组/双摄模组3.png') },
          { id: 4, name: '超高动态范围捕捉', url: require('@/assets/product_center/mobile/双摄模组/双摄模组4.png') }],
          mobileList_move: [
            { id: 1, name: '背景虚化', url: require('@/assets/product_center/mobile/双摄模组/双摄模组1.png') },
            { id: 2, name: '超大光圈', url: require('@/assets/product_center/mobile/双摄模组/双摄模组2.png') },
          ],
          imgLists: [
            { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
            { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
            { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
          ]
        }]
      } else if (state.id == 4) {
        state.navData = state.NBList
      } else if (state.id == 3) {
        state.navData = state.AIoTList
      } else if (state.id == 2) {
        state.navData = state.vehicleList
      } else if (state.id == 1) {
        state.navData = state.LEDList
      }


    });
    return {
      ...toRefs(state), ...methods, el_card, noMore, isRefreshBool
    };
  },
};
</script>

<style lang="less" scoped>
.elCard {
  overflow: hidden;
  width: 100%;
  height: 950px;
  display: flex;
  background-color: #F5F5F5;
  border-radius: 15px;

  .camera {
    flex: 2;
    padding: 120px 0px 30px 0px;

    .camera_img {
      margin-left: 30px;
      // width: 70%;
      // height: 74%;

      img {
        width: 100%;
        height: 66%;
      }
    }

    .camera_text {
      margin: 0 auto;
      width: 70%;
      height: 40%;
      padding: 0 30px 0 0;
      box-sizing: border-box;
      margin-bottom: 20px;

      h2 {
        margin-top: -10px;
        font-size: 24px;
        text-align: center;
      }

      p {
        Letter-spacing: 2px;
      }
    }
  }

  .imgList {
    flex: 4;
    padding: 40px 30px 40px 30px;
    display: flex;
    flex-direction: column;

    .imgList_top,
    .imgList_bottom {
      flex: 1;
    }

    .imgList_top {
      position: relative;

      li {
        margin-top: 20px;
        text-align: left;
        padding-left: 0 30px;
        box-sizing: border-box;
      }
    }

    .imgList_bottom {
      position: relative;
      box-sizing: border-box;
      padding-top: 18rem;


      .box_1 {
        position: relative;
        bottom: 60px;
        width: 100%;
        height: 100%;
        // background-color: red;
        display: flex;
        margin-top: -40px;

        .box_01 {
          flex: 1;
          text-align: center;

          img {
            width: 80%;
            margin-top: 30px;
            height: auto;

          }
        }

        .box_02 {
          text-align: center;
          margin: 0 auto;
          position: relative;

          img:nth-child(1) {
            width: 70%;
            margin-top: 30px;
            margin-left: 23px;
          }
        }

        .img_11 {
          width: auto;
          height: 300px;
          margin-left: 500px;
        }

        .img_22 {
          position: absolute;
          width: auto;
          height: 200px;
          left: -220px;
          top: 30px;
        }

        // .img_2 {
        //   flex: 1;
        //   padding: 35px;
        //   box-sizing: border-box;
        //   width: 30%;
        //   height: 65%;
        // }
      }
    }

    .imgList_bottom_1 {
      padding-top: 3rem;
    }
  }

}

.data_p {
  text-align: center;
  font-size: 24px;
  color: #666;
}

.el-tooltit {
  max-width: 400px;
  line-height: 180%;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

.box {
  width: 100%;

  p {
    font-size: 18px;
  }

  li {
    font-size: 18px;
  }

  .igs {
    padding: 3.6rem !important;
    margin-top: -3.8rem !important;
  }

  .loading {
    font-size: 24px;
    text-align: center;
  }

  .content {
    margin: 0 auto;
    max-width: 1920px;
    width: 90%;

    .content_01 {
      width: 100%;
      height: 100px;
      line-height: 100px;
      // background-color: red;
      float: right;
      margin-right: 11%;

      .p1 {
        // margin-right: 30px;
        display: block;
        float: right;
        cursor: pointer;
        height: 65px;
        width: auto;
        margin-right: 20px;
        text-align: center;
        overflow: hidden;
      }

      .p2 {
        border-bottom: 3px solid #ef3e2e;
      }
    }

  }



  .contentData {
    overflow: auto;
    margin: 0 auto;
    max-width: 1920px;
    width: 70%;
    overflow-y: hidden
  }
}

// 走马灯
.demonstration {
  color: var(--el-text-color-secondary);
}


::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

.imagess {
  width: 100%;
  height: auto;
  max-height: 600px
}

::v-deep .card {
  border-radius: 14px 14px 30px 30px;
  height: 180px;
  text-align: center;
  width: 100%;
  overflow: hidden;
  margin-left: 4px;

  h2 {
    margin: 0;
    padding: 0;
    font-size: 24px;
  }
}

.card_bottom_1 {
  margin-top: 100px;
}

.card_bottom {
  position: absolute;
  top: 5.12rem;
  left: 10px;
  z-index: 9;
  width: 100%;
  height: 150px;
  display: flex;
  display: flex;
  box-sizing: border-box;

  .box {
    flex: 1;

    p {
      text-align: left;
      text-align: center;
      width: 180px;
      overflow: hidden;
      height: 70px;
    }

    img {
      margin: 0 auto;
      width: 95%;
      padding: 10px;
      margin-top: -30px;
      box-sizing: border-box;
    }

  }
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;

    .che {
      height: 14rem !important;
    }

    .content_top {
      padding: 30px;
      text-align: center;

      p {
        display: inline-block;
        font-size: 60px;
        margin-right: 50px;

        cursor: pointer;
      }

      .p1 {
        border-bottom: 8px solid #ef3e2e;
      }
    }

    .content_move {
      padding: 30px;
      width: 96.8%;


      .box_top {
        position: relative;
        font-size: 60px;
        color: #fff;
        width: 50%;
        text-align: center;
        height: 150px;
        line-height: 150px;
        background-color: #5C5C5C;

        // margin-left: 1050px;
        .box_top_1 {
          position: absolute;
          // left: 100%;
          // width: 930px;
          // width: 100%;
          width: 200%;
          z-index: -1;
          top: 70px;
          height: 10px;
          background-color: #5C5C5C;
        }
      }


      .box_bottom {
        display: flex;

        h2 {
          font-size: 50px;
          margin: 30px 0 20px 0;
        }

        p {
          width: 90%;
          margin: 0 auto;
          text-align: left;
          font-size: 40px
        }

        .img_left {
          flex: 4;
          text-align: center;

          img {
            width: 80%;
            margin-top: 250px;
          }
        }

        .img_right {
          flex: 6;
          text-align: center;

          img {

            margin: 20px 40px 0;
          }
        }


      }
    }
  }
}
</style>